<template>
  <div>
    <Grid :data="grid" id="grid" class="box" v-if="grid" />
  </div>
</template>

<script>
export default {
  name: 'AutoCheckGridTooltip',
  components: {
    Grid: () => import('./AutoCheckGrid')
  },
  props: {
    grid: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="sass" scoped>
#grid
  width: 475px
</style>
